'use client';

import Link from 'next/link';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import classNames from 'classnames';
import { debounce } from 'lodash';
import { useDebounce } from 'usehooks-ts';

import ImageProxy from '@next-image-proxy';

import { useStoreAppsPreview } from '@query';

import { AppPreview } from '@api';

import { getMixPanelPage, MIXPANEL_EVENT_NAMES } from '@shared/api/mixPanel';
import { useNavLayout } from '@shared/common/components/Header/Header';
import { useMixPanel } from '@shared/common/providers/MixPanelProvider';
import { nextProjectUrl } from '@shared/constants/url';
import { APP_STATUS, AppStatus, getAppStatus } from '@shared/helpers/app';
import displayErrorToast from '@shared/helpers/displayErrorToast';

// import ImageProxy from '@next-image-proxy';
import SearchBar from '@ui/uikit/components/common/SearchBar';
import { UnderVerification } from '@ui/uikit/components/icons/mono/UnderVerification';
import { Upcoming } from '@ui/uikit/components/icons/mono/Upcoming';
import { Verificated } from '@ui/uikit/components/icons/mono/Verificated';
import { cn } from '@ui-uikit/lib/utils';

import NavLayoutLink from '../NavLayoutLink';

const getAppStatusConfig = (appStatus: AppStatus) => {
  return {
    [APP_STATUS.UNDER_VALIDATION]: {
      iconClassName: 'text-base-text-orange',
      Icon: UnderVerification,
    },
    [APP_STATUS.VALIDATED]: {
      iconClassName: 'text-base-text-pink',
      Icon: Verificated,
    },
    [APP_STATUS.UPCOMING]: {
      iconClassName: 'text-base-text-blue',
      Icon: Upcoming,
    },
    [APP_STATUS.UNVALIDATED]: null,
    [APP_STATUS.READ_ONLY]: null,
  }[appStatus];
};

// TODO: refactor
const HeaderSearchBar = ({
  className,
  isMobileHeader = false,
  onSeeMore,
  onSelect,
}: {
  className?: string;
  onSelect?: () => void;
  onSeeMore?: () => void;
  // TODO: rework without using prop
  isMobileHeader?: boolean;
}) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const searchParamsFind = searchParams.get('find') || '';
  const [search, setSearch] = useState(searchParamsFind);
  const [isFocus, setIsFocus] = useState(false);
  const searchBarInputRef = useRef<HTMLInputElement>(null);
  const debouncedSearch = useDebounce(search, 300);
  const { user } = useNavLayout();
  const { track } = useMixPanel();
  const userId = user?.data?.id;

  const trackApplicationSearchEvent = useCallback(
    (searchValue?: string) => {
      const page = getMixPanelPage();

      if (userId && page && searchValue) {
        track(MIXPANEL_EVENT_NAMES.APPLICATION_SEARCH, {
          distinct_id: userId,
          page,
          search: searchValue,
        });
      }
    },
    [track, userId],
  );

  const trackApplicationClickEvent = (application: string) => {
    const page = getMixPanelPage();

    if (userId && page) {
      track(MIXPANEL_EVENT_NAMES.APPLICATION_CLICK, {
        distinct_id: userId,
        page,
        application,
      });
    }
  };

  const {
    data,
    isPending: isLoading,
    isFetching,
    error,
  } = useStoreAppsPreview(
    { search: debouncedSearch },
    {
      enabled: isFocus && !!search && !!debouncedSearch,
      // placeholderData: keepPreviousData,
    },
    { onSuccess: trackApplicationSearchEvent },
  );
  const appsPreview: AppPreview[] = search && data?.data ? data.data : [];
  const totalApps = data?.total_apps || 0;

  const paramsQuery = useMemo(() => {
    return search ? new URLSearchParams({ find: search }).toString() : '';
  }, [search]);

  const handleFocusOut = debounce(() => {
    searchBarInputRef.current?.blur();
    setIsFocus(false);
  }, 300);

  const openSearchPage = () => {
    router.push(nextProjectUrl.store(`/search?${paramsQuery}`));
  };

  const handleChange = (value: string) => {
    setSearch(value);
  };

  useLayoutEffect(() => {
    setSearch(searchParamsFind);
  }, [searchParamsFind]);

  useEffect(() => {
    const handleScroll = () => {
      handleFocusOut();
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    handleFocusOut();
  }, [pathname]);

  if (error) {
    displayErrorToast({ error });
  }

  return (
    <SearchBar
      isMobileHeader={isMobileHeader}
      className={classNames(className)}
      value={search}
      placeholder="Apps, Games and more..."
      isOpen={!!search && !isLoading && isFocus}
      onChange={handleChange}
      onSearch={openSearchPage}
      isLoading={isFetching}
      inputRef={searchBarInputRef}
      onFocus={() => setIsFocus(true)}
      onBlur={handleFocusOut}
      onReset={() => {
        // NOTE: this case will only happen in store
        if (pathname === '/search') {
          openSearchPage();
        }
      }}
    >
      {!isLoading && (
        <div className="flex flex-col gap-1">
          {appsPreview.slice(0, 5)?.map((appPreview) => {
            const status = getAppStatus({
              status: appPreview.status,
              productionReady: appPreview.production_ready,
            });
            const statusIconConfig = status && getAppStatusConfig(status);
            const { Icon, iconClassName } = statusIconConfig || {};

            return (
              <NavLayoutLink
                href={nextProjectUrl.store(`/app/${appPreview.app_id}`)}
                className="flex items-center w-full gap-2 p-2 rounded-lg hover:bg-button-ghost-hover-bg transition-colors"
                key={appPreview.id}
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  trackApplicationClickEvent(appPreview.app_id);
                  onSelect?.();
                  setSearch('');
                  e.currentTarget.blur();
                }}
              >
                <ImageProxy
                  alt="App's icon"
                  className="size-8 xl-msq:size-10 flex-shrink-0 rounded-[0.25rem]"
                  src={appPreview.app_icon}
                  width={40}
                  height={40}
                />
                <div className="flex-1 text-left overflow-hidden">
                  <p className="text-caption-sm-strong-m xl-msq:text-caption-sm-strong-d truncate">
                    {appPreview.app_name}
                  </p>
                  <p className="text-caption-xs-m text-base-text-tertiary xl-msq:text-caption-xs-d truncate inline-flex gap-1">
                    {appPreview.category_name}
                    <span>•</span>
                    {appPreview.subcategory_name}
                  </p>
                </div>

                {Icon && <Icon className={cn('size-4', iconClassName)} />}
              </NavLayoutLink>
            );
          })}
        </div>
      )}

      {!isLoading && !!search && !appsPreview.length && (
        <div className="text-caption-sm-strong-d">No Apps or Games Found</div>
      )}

      {!isLoading && appsPreview.length > 5 && (
        <Link
          onClick={onSeeMore}
          className="msq-btn msq-btn-secondary msq-btn-md w-full mt-3"
          href={nextProjectUrl.store(`/search?${paramsQuery}`)}
        >
          Show All {totalApps ? `${totalApps}` : ''}
        </Link>
      )}
    </SearchBar>
  );
};

export default HeaderSearchBar;
