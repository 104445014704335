import React from 'react';

import ImageProxy from '@next-image-proxy';

import { MembershipTierId } from '@api';

import { User } from '@ui-uikit/lib/components/icons/mono';
import { cn } from '@ui-uikit/lib/utils';

import { getMembershipClassNames } from './utils';

interface UserAvatarProps {
  className?: string;
  src?: string;
  membershipTier?: MembershipTierId;
}

export const DEFAULT_AVATAR_PLACEHOLDER_HASH = '366a4ca5-cab7-404a-9ee9-4b79e56bc3cd';

const UserAvatar = ({ className, src, membershipTier }: UserAvatarProps) => {
  const membershipClassName = membershipTier ? getMembershipClassNames(membershipTier)?.avatar : '';
  const hasAvatar = !!src && src !== DEFAULT_AVATAR_PLACEHOLDER_HASH;

  return (
    <span className={cn('msq-avatar', membershipClassName, className)}>
      {hasAvatar && (
        <ImageProxy width={96} height={96} className="msq-avatar-img" src={src} alt="My Avatar" />
      )}

      {!hasAvatar && (
        <span className="msq-avatar-img">
          <User className="msq-avatar-icon" />
        </span>
      )}
    </span>
  );
};

export default UserAvatar;
