import Link from 'next/link';
import { useRouter } from 'next/navigation';

import React, { useState } from 'react';

import classNames from 'classnames';

import { useGetKYCBabtMutation } from '@query';

import BinanceLogo from '@uikit/icons/BinanceLogo';
import Checked from '@uikit/icons/Checked';
import CloseCircle from '@uikit/icons/CloseCircle';
import SumSub from '@uikit/icons/SumSub';
import {
  ExternalLink,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeading,
  ModalProps,
  Spinner,
} from '@uikit/index';

import { LAUNCHPAD_INTERNAL_ROUTES } from '@shared/constants';
import displayErrorToast from '@shared/helpers/displayErrorToast';
import useTmaRestrictionModal from '@shared/hooks/useTmaRestrictionModal';

type Status = 'success' | 'fail' | 'waiting';

interface KycRequiredModalProps extends Omit<ModalProps, 'children'> {
  isDisabledSumSub?: boolean;
  isDisabledBinance?: boolean;
  onSuccess?: () => void;
}

export const KycRequiredModal = (props: KycRequiredModalProps) => {
  const { mutateAsync: updateBabt } = useGetKYCBabtMutation();
  const [status, setStatus] = useState<Status>('waiting');
  const [loading, setLoading] = useState(false);
  const router = useRouter();

  const { isAppOpenedInTelegram, tmaRestrictionModal } = useTmaRestrictionModal();

  const closeModal = () => {
    props.onOpenChange?.(false);
  };

  const handleSumSubVerify = () => {
    if (isAppOpenedInTelegram) {
      return tmaRestrictionModal.open();
    }

    router.push(LAUNCHPAD_INTERNAL_ROUTES.kyc);

    closeModal();
    props?.onSuccess?.();
  };

  const handleBinanceVerify = async () => {
    if (isAppOpenedInTelegram) {
      return tmaRestrictionModal.open();
    }

    setLoading(true);

    try {
      const result = await updateBabt();
      setStatus(result.hasBABT ? 'success' : 'fail');
    } catch (error) {
      if (error) {
        setStatus('fail');
        displayErrorToast({ error });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal {...props}>
      <ModalContent className="flex flex-col items-center py-6 px-4 sm:px-12 w-full max-w-[472px]">
        <ModalCloseButton className="!mb-3" />

        <ModalHeading className="mb-4">KYC Verification Required</ModalHeading>

        <p className="text-neutral-400 mb-8 text-center">
          To join the Magic Launchpad IDOs, please verify your identity using one of the following
          secure methods:
        </p>

        {status === 'waiting' && (
          <div className="mb-6 bg-secondary-50 py-4 px-2 w-full rounded-lg">
            <ul className="flex flex-col gap-4">
              <li className="flex justify-start items-start gap-1">
                <span className="w-4 h-4 min-w-4 rounded-full bg-secondary-600 text-neutral-50 block text-center leading-4">
                  1
                </span>
                <p>
                  SumSub: Use our third-party KYC service, SumSub. Verification takes less than a
                  few minutes.
                </p>
              </li>
              <li className="flex justify-start items-start gap-1">
                <span className="w-4 h-4 min-w-4 rounded-full bg-secondary-600 text-neutral-50 block text-center leading-4">
                  2
                </span>
                <p>
                  Binance KYC: Use the Binance KYC feature by minting a Binance Account Bound (BAB)
                  token. For more information,{' '}
                  <ExternalLink
                    className="text-primary-700 underline"
                    href="https://www.binance.com/en/support/faq/how-to-mint-binance-account-bound-bab-token-bacaf9595b52440ea2b023195ba4a09c"
                  >
                    click here
                  </ExternalLink>
                  .
                </p>
              </li>
            </ul>
          </div>
        )}

        {status === 'success' && (
          <div className="mb-6 bg-green-50 p-4 w-full rounded-lg">
            <p className="font-semibold leading-[52px] items-center justify-start gap-2">
              <Checked className="icon-sm text-green-800" /> Binance KYC Passed
            </p>
          </div>
        )}

        {status === 'fail' && (
          <div className="mb-6 bg-red-200 p-4 w-full rounded-lg">
            <p className="leading-[52px] flex items-center justify-start gap-2">
              <CloseCircle className="icon-sm text-red-600" /> Binance Verification Failed
            </p>
          </div>
        )}

        <div className="flex flex-col gap-4 w-full">
          {status !== 'success' && (
            <>
              <button
                className={classNames(
                  'button button-solid-secondary button-lg w-full flex justify-center items-center gap-2',
                  {
                    'bg-[#F9BA35] hover:bg-[#f9bb35cb] text-neutral-50':
                      !props?.isDisabledBinance && !loading,
                  },
                )}
                onClick={handleBinanceVerify}
                disabled={loading || !!props?.isDisabledBinance}
              >
                <BinanceLogo className="text-neutral-50 icon-sm" />{' '}
                {status === 'fail' ? 'Binance Verification Failed, Retry' : 'Verify with Binance'}{' '}
                {loading && <Spinner className="h-4 w-4" />}
              </button>
              <button
                className={classNames(
                  'button button-solid-secondary  text-neutral-700 button-lg w-full flex justify-center items-center gap-2',
                  {
                    'bg-[#BDFFEF] hover:bg-[#bdffeeb2]': !props?.isDisabledSumSub && !loading,
                  },
                )}
                onClick={handleSumSubVerify}
                disabled={loading || !!props?.isDisabledSumSub}
              >
                <SumSub className="icon-sm" />
                Verify with SumSub
              </button>
            </>
          )}
          {status === 'success' && (
            <Link
              className="button button-solid-secondary button-lg w-full"
              href={LAUNCHPAD_INTERNAL_ROUTES.projects}
              onClick={closeModal}
            >
              Explore IDOs
            </Link>
          )}
        </div>
      </ModalContent>
    </Modal>
  );
};

export default KycRequiredModal;
