import Link, { type LinkProps } from 'next/link';

import { type AnchorHTMLAttributes, forwardRef, type ReactNode, Ref } from 'react';

export type NextLinkProps = LinkProps &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> & {
    children?: ReactNode;
  };

const NextLink = (
  {
    // Turn next/link prefetching off by default.
    // @see https://github.com/vercel/next.js/discussions/24009
    prefetch = false,
    ...rest
  }: NextLinkProps,
  ref: Ref<HTMLAnchorElement>,
) => {
  return <Link prefetch={prefetch} {...rest} ref={ref} />;
};

export default forwardRef(NextLink);
