export const OLD_VALIDATION_STATUS = {
  CREATED: 'CREATED',
  DEPLOYING: 'DEPLOYING',
  FAILED: 'FAILED',
  DONE: 'DONE',
  DISABLE: 'DISABLE',
  INVALID: 'INVALID',
  TOFIX: 'TOFIX',
  APPROVED: 'APPROVED',
  ONPREAPPROVE: 'ONPREAPPROVE',
  PENDING: 'PENDING',
  VALIDATORS_ADDED: 'VALIDATORS_ADDED',
  ROUND_STARTED: 'ROUND_STARTED',
  FAILED_REFUND_TAKEN: 'FAILED_REFUND_TAKEN',
  WAITING_FOR_STAKE: 'WAITING_FOR_STAKE',
  WAITING_FOR_VOTE_START: 'WAITING_FOR_VOTE_START',
};

export const NEW_VALIDATION_STATUS = {
  EXTERNAL_VALIDATION: 'EXTERNAL_VALIDATION',
  EXTERNAL_VALIDATION_FAILED_NO_QUORUM: 'EXTERNAL_VALIDATION_FAILED_NO_QUORUM',
  EXTERNAL_VALIDATION_FAILED_BAD_SCORE: 'EXTERNAL_VALIDATION_FAILED_BAD_SCORE',
  PROD_READY_TO_FIX: 'PROD_READY_TO_FIX',
  APP_REJECTED: 'APP_REJECTED',
  PROD_READY_ON_PREAPPROVE: 'PROD_READY_ON_PREAPPROVE',
  UPCOMING: 'UPCOMING',
  UPCOMMING_ON_PREAPPROVE_APPROVED: 'UPCOMMING_ON_PREAPPROVE_APPROVED',
  UPCOMMING_TO_FIX: 'UPCOMMING_TO_FIX',
  UPCOMMING_ON_PREAPPROVE: 'UPCOMMING_ON_PREAPPROVE',
  REVALIDATION_PROD_READY_ON_PREAPPROVE: 'REVALIDATION_PROD_READY_ON_PREAPPROVE',
  REVALIDATION_UPCOMMING_ON_PREAPPROVE: 'REVALIDATION_UPCOMMING_ON_PREAPPROVE',
  EXTERNAL_VALIDATION_SUCCESS: 'EXTERNAL_VALIDATION_SUCCESS',
  INTERNAL_VALIDATION_SUCCESS: 'INTERNAL_VALIDATION_SUCCESS',
  UNVALIDATED: 'UNVALIDATED',
  READ_ONLY: 'READ_ONLY',
};

export const VALIDATION_STATUS = {
  ...OLD_VALIDATION_STATUS,
  ...NEW_VALIDATION_STATUS,
};

export type ValidationStatus = keyof typeof VALIDATION_STATUS;

export type ValidationStatusValue = (typeof VALIDATION_STATUS)[ValidationStatus];
